import { DynamicImage, DynamicVideo } from '@components/common'
import { Button } from '@components/ui'
import { PrismicImage } from '@lib/prismic'
import cn from 'classnames'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import tailwindConfig from 'tailwind.config.js'
import resolveConfig from 'tailwindcss/resolveConfig'
const fullConfig = resolveConfig(tailwindConfig as any)

type SVGImage = {
  url: string
  dimensions: {
    width: number
    height: number
  }
  alt: string
}

interface props {
  index?: number
  primary: {
    button_link: string
    button_style: string
    button_text: string
    button_colour_light: boolean
    desktop_image: PrismicImage
    hide_button: boolean
    mobile_image: PrismicImage
    svg_image: SVGImage
    use_video_background: boolean
    loop_background_video: boolean
    video_background_desktop: {
      url: string
    }
    video_background_mobile: {
      url: string
    }
  }
}

const ImmersiveBanner: FC<props> = ({ primary, index }) => {
  const { pathname } = useRouter()
  const onHomePage = pathname === '/'
  const {
    desktop_image,
    mobile_image,
    button_link,
    button_style,
    button_text,
    button_colour_light,
    svg_image,
    use_video_background,
    loop_background_video,
    video_background_desktop,
    video_background_mobile,
  } = primary
  const { screens } = fullConfig.theme as Theme
  const breakPointNum = parseInt(screens['tablet'].split('px').join(''))
  const breakPoint = screens['tablet']
  const [navigationV2, setNavigationV2] = useState(false)

  const imageProps = {
    quality: 100,
  }
  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    const navBarV2: any = document.getElementsByClassName('navigationHeader')?.[0]

    if (navBarV2) {
      setNavigationV2(true)
    }
  }, [])

  return (
    <section
      className={cn(`immersive-banner relative flex justify-center `, {
        'immersive-banner-negative-margin': index == 0 && onHomePage && navigationV2,
      })}
      ref={ref}
    >
      <div
        className={cn(`immersive-banner__background absolute tablet:relative`, {
          'immersive-banner__background-extra-height': index == 0 && onHomePage && navigationV2,
        })}
      >
        {!use_video_background && desktop_image.url && inView && (
          <div className={`relative h-full`}>
            <DynamicImage mediaDesktop={desktop_image} mediaMobile={mobile_image} breakPoint={1024} {...imageProps} />
          </div>
        )}
        {use_video_background && inView && (
          <div className="relative w-full h-full">
            <DynamicVideo
              mediaDesktop={video_background_desktop}
              mediaMobile={video_background_mobile}
              breakPoint={breakPointNum}
              settings={{ playsInline: true, autoPlay: true, loop: loop_background_video, muted: true }}
            />
          </div>
        )}
      </div>

      <div
        className={cn(`wrapper h-full absolute z-1 top-0  grid grid-cols-8 tablet:grid-cols-12 gap-12 tablet:gap-16`, {
          'wrapper-position': index == 0 && onHomePage && navigationV2,
        })}
      >
        <div
          className={cn(
            `immersive-banner__content absolute tablet:static tablet:col-start-2 tablet:col-span-10 col-span-8 flex flex-col justify-center items-center w-full h-full py-60`,
            {
              'light-text': button_colour_light,
            }
          )}
        >
          {svg_image.url && (
            <div className="mb-60 px-30">
              <Image
                src={svg_image.url}
                width={svg_image.dimensions.width}
                height={svg_image.dimensions.height}
                alt={svg_image.alt || 'Sheet Society'}
              />
            </div>
          )}
          {button_text && (
            <Link href={button_link} passHref legacyBehavior>
              <Button
                Component="a"
                variant={button_style.toLowerCase()}
                bg="dark"
                className={`absolute bottom-32 tablet:bottom-60`}
              >
                {button_text}
              </Button>
            </Link>
          )}
        </div>
      </div>
      <style jsx global>
        {`
          .immersive-banner {
            height: 100%;
            width: 100%;
            padding-top: 100%;
          }
          @media screen and (min-width: ${breakPoint}) {
            .immersive-banner {
              height: 70vh;
              padding: 0;
            }
          }
          .immersive-banner__content.light-text {
            color: var(--color-white);
          }
          .immersive-banner__background {
            height: 100%;
            width: 100%;
            top: 0;
          }
          .immersive-banner-negative-margin {
            top: -75px;
          }
          .immersive-banner__background-extra-height {
            height: calc(100% + 75px);
          }
          .wrapper-position {
            top: 75px;
          }
          @media (min-width: 1024px) {
            .immersive-banner-negative-margin {
              top: -78px;
            }
            .immersive-banner__background-extra-height {
              height: calc(100% + 78px);
            }
            .wrapper-position {
              top: 78px;
            }
          }
        `}
      </style>
    </section>
  )
}

export default ImmersiveBanner
