module.exports = {
  future: {
    purgeLayersByDefault: true,
    applyComplexClasses: true,
  },
  purge: {
    content: [
      './pages/**/*.{js,ts,jsx,tsx}',
      './components/**/*.{js,ts,jsx,tsx}',
      './containers/**/*.{js,ts,jsx,tsx}',
    ],
    options: {
      safelist: {
        standard: ['outline-none'],
      },
    },
  },
  theme: {
    spacing: {
      0: '0px',
      1: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      7: '7px',
      8: '8px',
      9: '9px',
      10: '10px',
      11: '11px',
      12: '12px',
      13: '13px',
      14: '14px',
      15: '15px',
      16: '16px',
      17: '17px',
      18: '18px',
      19: '19px',
      20: '20px',
      21: '21px',
      22: '22px',
      23: '23px',
      24: '24px',
      25: '25px',
      26: '26px',
      28: '28px',
      30: '30px',
      32: '32px',
      34: '34px',
      35: '35px',
      36: '36px',
      38: '38px',
      40: '40px',
      42: '42px',
      44: '44px',
      46: '46px',
      48: '48px',
      49: '49px',
      50: '50px',
      52: '52px',
      53: '53px',
      56: '56px',
      60: '60px',
      64: '64px',
      72: '72px',
      75: '75px',
      77: '77px',
      78: '78px',
      80: '80px',
      84: '84px',
      87: '87px',
      88: '88px',
      92: '92px',
      100: '100px',
      104: '104px',
      106: '106px',
      118: '118px',
      120: '120px',
      123: '123px',
      124: '124px',
      126: '126px',
      130: '130px',
      135: '135px',
      136: '136px',
      144: '144px',
      146: '146px',
      150: '150px',
      156: '156px',
      158: '158px',
      160: '160px',
      170: '170px',
      176: '176px',
      177: '177px',
      189: '189px',
      200: '200px',
      205: '205px',
      208: '208px',
      209: '209px',
      218: '218px',
      224: '224px',
      240: '240px',
      304: '304px',
      335: '335px',
      340: '340px',
      370: '370px',
      375: '375px',
      380: '380px',
      395: '395px',
      450: '450px',
      504: '504px',
      600: '600px',
    },
    fontFamily: {
      'g-bold': ['GrotesqueBold', 'Roboto', 'Arial', 'sans-serif'],
      'g-medium': ['GrotesqueMedium', 'Roboto', 'Arial', 'sans-serif'],
      'g-regular': ['GrotesqueRegular', 'Roboto', 'Arial', 'sans-serif'],
      'b-regular': ['BradfordLLRegular'],
    },
    aspectRatio: {
      square: '1 / 1',
      '16/10': '16 / 10',
      '5/3': '5/3',
    },
    maxWidth: {
      laptop: '1024px',
      desktop: '1440px',
      full: '100%',
      md: '28rem',
      65: '65px',
      75: '75px',
      130: '130px',
      324: '324px',
      342: '342px',
      351: '351px',
      394: '394px',
      408: '408px',
      600: '600px',
    },
    minWidth: {
      60: '60px',
      100: '100px',
      120: '120px',
      160: '160px',
      189: '189px',
      200: '200px',
      209: '209px',
      400: '400px',
      600: '600px',
      full: '100%',
      '2/3': '66%',
    },
    maxHeight: {
      24: '24px',
      28: '28px',
      32: '32px',
      44: '44px',
      48: '48px',
      60: '60px',
      146: '146px',
      206: '206px',
      244: '244px',
      280: '280px',
      348: '348px',
      '6/10': '60%',
      '8/10': '80%',
      '9/10': '90%',
      full: '100%',
      'screen-80': '80vh',
      screen: '100vh',
    },
    minHeight: {
      60: '60px',
      56: '56px',
      72: '72px',
      78: '78px',
      100: '100px',
      204: '204px',
      252: '252px',
      380: '380px',
      'screen-80': '80vh',
      screen: '100vh',
    },
    colors: {
      primary: '#897161',
      light: '#B9ACA1',
      dark: '#505050',
      ecru: '#FBF9F6',
      charcoal: '#6A6A6A',
      doveGrey: '#AFB1B4',
      beige: '#BDABA0',
      mocha: '#A17F66',
      moss: '#7F7A64',
      blush: '#D6BAB2',
      tangelo: '#BE4D35',
      midGrey: '#C2C2C2',
      lightGrey: '#DEDEDE',
      lightestGrey: '#F1F1F1',
      errorRed: '#BC3636',
      successGreen: '#3FA011',
      warningAmber: '#DC6C10',
      sale: '#BE4D35',
    },
    textColor: {
      black: '#000000',
      light: '#595959',
      lightGrey: '#DEDEDE',
      white: '#ffffff',
      light: '#606060',
      hover: '#A67247',
      grey: '#2f2f2f',
      darkGrey: '#c2c2c2',
      doveGrey: '#AFB1B4',
      charcoal: '#6A6A6A',
      moss: '#7F7A64',
      midGrey: '#C2C2C2',
      sale: '#BE4D35',
      warmWhite: '#F2EBDF',
      tangelo: '#BE4D35',
      terrcotta: '#A67247',
      errorRed: '#BC3636',
      newSage: '#6C704A',
      almostBlack: '#343433',
      hoverGrey: '#8B8C92',
      lilac: '#CBB2D8',
    },
    backgroundColor: {
      ecru: '#FBF9F6',
      primary: '#897161',
      white: '#ffffff',
      black: '#000000',
      transparent: 'transparent',
      light: '#B9ACA1',
      dark: '#505050',
      grey: '#F5F5F5',
      footer: '#595959',
      lightGrey: '#DEDEDE',
      lightestGrey: '#F1F1F1',
      mocha: '#A17F66',
      doveGrey: '#AFB1B4',
      warning: '#DC6C10',
      charcoal: '#6A6A6A',
      sale: '#BE4D35',
      newProduct: '#A8B09F',
      limitedEdition: '#AF9A76',
      lightBg: '#f7f3ed',
      tangelo: '#BE4D35',
      warmWhite: '#F2EBDF',
      sage: '#A8B09F',
      sageLight: '#D3D7CF',
      terracotta: '#A67247',
      almostBlack: '#343433',
      lilac: '#E5D8EB',
      clay: '#EEE8E3',
      newSage: '#6C704A',
      brandLilac: '#CBB2D8',
    },
    borderColor: {
      transparent: 'transparent',
      primary: '#897161',
      light: '#B9ACA1',
      dark: '#505050',
      black: '#000000',
      white: '#ffffff',
      grey: '#e5e5e5',
      lightGrey: '#DEDEDE',
      lightestGrey: '#F1F1F1',
      midGrey: '#C2C2C2',
      doveGrey: '#AFB1B4',
      divider: '#DEDEDE;',
      ecru: '#FBF9F6',
      charcoal: '#6A6A6A',
      warmWhite: '#F2EBDF',
      errorRed: '#BC3636',
      successGreen: '#3FA011',
      newSage: '#6C704A',
      newClay: '#DDD1C7',
    },
    boxShadow: {
      'outline-normal': '0 0 0 2px #595959',
      'outline-error': '0 0 0 2px #BC3636',
      magical:
        'rgba(0, 0, 0, 0.02) 0px 30px 30px, rgba(0, 0, 0, 0.03) 0px 0px 8px, rgba(0, 0, 0, 0.05) 0px 1px 0px',
    },
    divideOpacity: {
      0: '0',
    },
    divideColor: {
      primary: '#595959',
      divider: '#DEDEDE',
    },
    borderRadius: {
      full: '9999px',
      none: '0',
      DEFAULT: '1px',
      2: '2px',
      3: '3px',
      4: '4px',
      5: '5px',
      6: '6px',
      8: '8px',
      10: '10px',
      12: '12px',
      16: '16px',
      22: '22px',
      24: '24px',
    },
    borderWidth: {
      DEFAULT: '1px',
      0: '0px',
      2: '2px',
      3: '3px',
      4: '4px',
    },
    fontSize: {
      7: '7px',
      9: '9px',
      10: '10px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      22: '22px',
      24: '24px',
      26: '26px',
      28: '28px',
      32: '32px',
      36: '36px',
      40: '40px',
      48: '48px',
      50: '50px',
      56: '56px',
      60: '60px',
      72: '72px',
    },
    lineHeight: {
      11: '11px',
      12: '12px',
      14: '14px',
      16: '16px',
      18: '18px',
      20: '20px',
      22: '22px',
      24: '24px',
      28: '28px',
      32: '32px',
      36: '36px',
      38: '38px',
      40: '40px',
      44: '44px',
      48: '48px',
      52: '52px',
      56: '56px',
      60: '60px',
      64: '64px',
      68: '68px',
      72: '72px',
      100: '100px',
    },
    letterSpacing: {
      spacingTight: '-1.5px',
      spacingTighter: '-1.2px',
      spacingBitTigher: '-0.16px',
      none: '0px',
      spacing: '0.3px',
      spacingWide: '0.5px',
      spacingBitWider: '0.6px',
      spacingWider: '1px',
      spacingWidest: '1.2px',
      8: '0.08em',
      wide: '.025em',
      wider: '.05em',
    },
    scale: {
      110: '1.1',
      120: '1.2',
    },
    screens: {
      mobileM: '375px',
      tablet: '768px',
      laptop: '1024px',
      desktop: '1280px',
      container: '1440px',
    },
    extend: {
      transitionProperty: {
        visibility: 'visibility',
      },
      gridTemplateRows: {
        8: 'repeat(8, minmax(0, 1fr))',
      },
    },
  },
  variants: {
    extend: {
      borderWidth: ['hover', 'focus'],
    },
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    require('tailwind-scrollbar-hide'),
    require('@tailwindcss/aspect-ratio'),
  ],
  darkMode: false,
}
